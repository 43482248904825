import React from 'react'
import '../../components/pages/Videos.css'
function Videos() {
  return (
    <div className='videos'>
        <h1 className='title-service'>הגלריית הסרטונים שלנו</h1>
        <div>
            <div>
                 <iframe className="video" src={`https://www.youtube.com/embed/rKS3yWZTbW4`}></iframe>
            </div>
           <div>
                <iframe  className="video" src={`https://youtube.com/embed/xXUzreziUn4`}></iframe>
           </div>
           
           
        </div>
        
    </div>
  )
}

export default Videos