import React from 'react'
import './Home.css'
import '../Accessibility.css'
import Carousel from '../Carousel'
import 'bootstrap/dist/css/bootstrap.css'
import {HashLink} from "react-router-hash-link"
import 'bootstrap-icons/font/bootstrap-icons.css'

function Main() {
  return (
    <div className="home">
        <div className='scroll-container'>
                <p className='scroll-text'>
                    חברת המעליות  - מעוף מעליות מציעה שירות אדיב, אמין ומקצועי עם התקנת מעליות איכותיות וברמה גבוהה. מוזמנים להתרשם! 
                </p>
        </div>
        <div className="header-home">
            <h6 className="">חברת המעליות</h6>
            <h1 className="main-title">מעוף מעליות</h1>
            <h3 className="">מתמחה בהתקנה והוספת מעליות או מעלונים בבניינים קיימים ובתים פרטיים. <br/> הוספת מעליות ביתיות בפנטהאוזים.</h3>
            <div className="div-carousel">
                <Carousel />
            </div>
            
        </div>
        <div>
            <h2 className="title-add-elevator">למה כדאי להוסיף מעלית?</h2>
            <div className="advantages">
                <div className="advantage">
                    <h5>עליית ערך הדירה בשיעורים שבין 30%-15%</h5>
                </div>
                <div className="advantage">
                    <h5>נוחות ואיכות חיים משודרגת וגבוהה יותר</h5>
                </div>
                <div className="advantage">
                    <h5>חיזוק יסודות הבניין כחלק מעבודות ההתקנה</h5>
                </div>
                <div className="advantage">
                    <h5>דירה אטרקטיבית יותר לרוכשים פוטנציאליים</h5>
                </div>
            </div>
        </div>
        <div>
            <h2 className='title-all-services'> שירותי חברת מעוף מעליות</h2>
            <div className='grid-services'>
                <div className='service'>
                    <HashLink className='text-service' to="/שרותים">התקנת מעליות</HashLink>
                    <div className="emoji">🛗</div>
                </div>
                <div className='service'>
                    <HashLink className='text-service' to="/שרותים#panelsStayOpen-headingTwo" >בניית פיר מעלית</HashLink>
                    <div className="emoji">🚜</div>
                </div>
                <div className='service'>
                    <HashLink className='text-service' to="/שרותים#panelsStayOpen-collapseTwo">הוצאת היתר בנייה</HashLink>
                    <div className="emoji">📃</div>
                </div>
                <div className='service'>
                    <HashLink className='text-service'to="/שרותים#panelsStayOpen-collapseThree">שינוי מהלך מדרגות</HashLink>
                    <div className="emoji">🪜</div>
                </div>
                <div className='service'>
                    <HashLink className='text-service' to="/שרותים#panelsStayOpen-collapseFour">תכנון וייצור מעליות</HashLink>
                    <div className="emoji">
                       <i className="bi bi-gear-fill"></i> 
                    </div>
                    
                    
                </div>
                <div className='service'>
                    <HashLink className='text-service' to="/שרותים#panelsStayOpen-collapseFive">הוספת מעלונים</HashLink>
                    <div className="emoji">🛗</div>
                </div>
                
                <div className='service'>
                    <HashLink to="/שרותים#panelsStayOpen-collapseSeven" className='text-service'>מתן שירות למעליות</HashLink>
                    <div className="emoji">
                       <i className="bi bi-tools"></i> 
                    </div>
                    
                </div>
        </div>
        </div>
        <div>
            <h2 className='service_title'>קבצים להורדה</h2>
            <div className='documents-list'>
                <a className='document' href={require('../../documents/acoustics1.pdf')}>אקוסטיקה1</a>
                <a className='document' href={require('../../documents/acoustics2.pdf')}
>אקוסטיקה2</a>
                <a className='document' href={require('../../documents/Precautions.pdf')}>הוראות בטיחות לעבודה</a>
                <a className='document' href={require('../../documents/SteelRopes.pdf')}>חבלי פלדה במעלית</a>
                <a className='document' href={require('../../documents/Introduction blocked.pdf')}>מבוא חסום</a>
                <a className='document' href={require('../../documents/Hydraulic Sabbath.pdf')}>מפרט שבת-הידראולית</a>
                <a className='document' href={require('../../documents/electric sabbath.pdf')}>מפרט שבת- חשמלית</a>
                <a className='document' href={require('../../documents/fall protection equipment.pdf')}>ציוד נגד נפילה</a>
                <a className='document' href={require('../../documents/firemen.pdf')}>תוספת מפתח כבאים</a>
                <a className='document' href={require('../../documents/Standard for the disabled.pdf')}>תקן לנכים פנים המבנה</a>
                <a className='document' href={require('../../documents/Construction regulations.pdf')}>תקנות בנייה</a>
            </div>
        </div>
    </div>
  )
}

export default Main