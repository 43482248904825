import React from 'react'

import './Process.css'

function Process(){
 
    return(
        <div className='process'>
            <h1 className='title-service'>תהליך הוספת מעלית</h1>
            <h2 className='txt-process'>הבנתם שאתם זקוקים למעלית, מעלית בבניין ישן, מעלית בבניין קיים,  מעלית בפנטהאוז, מעלית בבית פרטי. חברת מעוף מעליות כאן בשביל להגשים לכם את החלום בצורה הטובה ביותר והמקצועית ביותר עם יחסי אנוש מצויינים. אז מה השלבים להוספת מעלית? </h2>
            <div className='stages'>
             
            <div className='stage'>
            
                <h3 className='stage-txt'>1. יצירת קשר - <a href='/צור קשר' className='a-process'>מתקשרים או שולחים טופס </a>
                 לחברת מעוף מעליות, לבקשת התחלת התהליך אנו זקוקים רק לשם, מספר טלפון, וכתובת.</h3>
            </div>
            <div className='stage'>
                <h3 className='stage-txt'> 2. הגעה של איש מקצוע לכתובת המבוקשת - נציג מטעם החברה מגיע בחינם לצורך מדידות, חלופות והתסכלות על השטח. הוא מכין סרטוט של המקום כדי לדעת גדלים אפשריים למעלית.</h3>
            </div>
            <div className='stage'>
                <h3 className='stage-txt'>3. הצעת מחיר - אחרי שיש גדלים והבנה מה בדיוק נדרש כדי להוסיף מעלית, הלקוח מקבל הצעת מחיר המותאמת למקום</h3>
            </div>

            <div className='stage'>
                <h3 className='stage-txt'>4. חתימת על חוזה - הלקוח הבין שהצעת המחיר של חברת מעוף מעליות היא הכי טובה והגיונית. החברה מנפיקה חוזה לפרויקט.</h3>
            </div>
            <div className='stage'>
                <h3 className='stage-txt'>5. התחלת עבודה - לאחר החתימה על החוזה : <br/> תכנון הנדסי מוקדם לאחר המדידות בשטח ולאחר בדיקת בחלופות המתאימות למבנה ספציפי. <br/> הכנת תוכנית הגשה לעירייה לקבלת היתר בנייה.</h3>
            </div>
            <div className='stage'>
                <h3 className='stage-txt'>6. תכנון הפרויקט - אחרי קבלת ההיתר ואישור תחילת העבודה, מגיעים שוב לשטח למדידות וחפירת בור היסוד לפיר. <br/> הקמת/בניית הפיר בהתאם לשטח</h3>
            </div>
            <div className='stage'>
                <h3 className='stage-txt'>7. הזמנה וייבוא חלקי המעלית או ייבוא קומפלט של המעלית</h3>
            </div>
            <div className='stage'>
                <h3 className='stage-txt'>8. הרכבה בשטח , חיפוי הפיר וגמר עבודות הבינוי</h3>
            </div>
            
            
            <div className='stage'>
                <h3 className='stage-txt'>9. התקנת מערכות המעלית ותא המעלית</h3>
            </div>
            <div className='stage'>
                <h3 className='stage-txt'> 10. הזמנת חברת החשמל לצורך קבלת טופס 4, ולאחר מכן הרצת המערכות לאחר ההתקנה.</h3>
            </div>
            
            <div className='stage'>
                <h3 className='stage-txt'> 11. הזמנת מכון התקנים לבדיקה ואישור המעלית לנסיעה</h3>
            </div>
            <div className='stage'>
                <h3 className='stage-txt'>12. מסירת המעלית ללקוח - בעת המסירה מתבצע גם הסבר על תפעול המעלית ושימוש נכון.</h3>
            </div>
            <div className='stage'>
                <h3 className='stage-txt'> 13. ליווי צמוד - מתן שירות</h3>
            </div>
            </div>
        </div>
    );
}
export default Process