import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();
const responsive = {
    10: { items: 1 },
    578: { items: 2 },
    1034: { items: 3 },
};
const items = [
	<img className="img-gallery" src="1.jpg"  onDragStart={handleDragStart} role="presentation" />,
	<img className="img-gallery" src="2.jpg" onDragStart={handleDragStart} role="presentation" />,
	<img className="img-gallery" src="3.jpg" onDragStart={handleDragStart} role="presentation" />,

    <img className="img-gallery" src="4.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" src="5.jpeg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" src="6.jpeg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" src="7.jpeg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" src="8.jpeg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" src="9.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" src="10.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" src="11.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" src="12.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" src="13.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" src="14.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" src="15.jpg" onDragStart={handleDragStart} role="presentation" />,
    
];
function Carousel() {
  return (
    <AliceCarousel mouseTracking items={items} controlsStrategy='alternate' responsive={responsive}/>
  )
}

export default Carousel