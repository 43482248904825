import React,{useEffect, useState} from 'react'
import '../../App.css'
import './ProjectDetails.css'
import 'bootstrap/dist/css/bootstrap.css'
import {useLocation} from 'react-router-dom'

import {storge} from '../../firebase-config' 
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage' 
import Logout from '../Logout'



function ProjectDetails(){
     
    let num = useLocation().state;
    const [values, setValues] = useState({
        
        workOffice: '',
        dateSignature: '',
        elevatorType: '',
        endDate: '',
        projectName: '',
        engineer: '',
        planning: null,
        procurement: null,
        workPlan: '',
        projectNum: num,
        electricCompany:'',
        standardsInstitute: '',
        receivElevator: ''
    })
   
    const [fileUpload, setFileUpload] = useState(null)
    const [Url,setUrl ] = useState('')

    useEffect(()=>{
        
        fetch(`http://localhost:9000/project/get-all-project-data?projectNum=${num}`,{
            method: 'GET',
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                "Accept": "application/json"},
            
      
          }).then((response)=> response.json()).then((data)=>{
            if(data.status==='error'){
                return alert("useEffect",data.message)
            }
            console.log(data.data[0])
            setValues(data.data[0])
            console.log(values)
            if(data.data[0].workPlan === 1){
                getDownloadURL(ref(storge,`files/${fileWork}`)).then((url)=>{
                    setUrl(url)
                    console.log(url)
                   }) 
            }
          })
       
    },[])
    const onlyDate =(dateTime)=>{
        if(dateTime!= null){
            const [date,] = dateTime.split('T')
            return date
        }
        
    }
    
    const fileWork = values.projectNum +".pdf"
    
    const updateA = (e) => {
        e.preventDefault()
        console.log("name", values.projectName)
        fetch(`http://localhost:9000/project/update-first-details?projectNum=${num}`,{
            method: 'POST',
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json',
                "Accept": "application/json"},
          body: JSON.stringify(values)
        }).then((response)=>response.json()).then((data)=>{
            if(data.status==='error')  console.log(data.message)
            else alert("הנתונים נשמרו בהצלחה!")

        })
        
    }
    const addEngineer=(e)=>{
        e.preventDefault()
        console.log(values.engineer)
        fetch('http://localhost:9000/project/add-engineer',{
            method: 'POST',
          headers: {
            "Access-Control-Allow-Origin": "*",'Content-Type': 'application/json',
                "Accept": "application/json"},
          body: JSON.stringify({engineer: values.engineer, username: values.projectName} )
        }).then(response=>response.json()).then((data)=>{
            if(data.status==='error') return alert("משהו לא בסדר")
            if(data.status ==='success')
            return alert("שם המהנדס נשמר בהצלחה")
        })
        

    }
    const planning=()=>{
        console.log("planning")
        fetch('http://localhost:9000/project/planning-phase',{
            method: 'POST',
          headers: {
            "Access-Control-Allow-Origin": "*",'Content-Type': 'application/json',
                "Accept": "application/json"},
          body: JSON.stringify({ username: values.projectName} )
        }).then(response=>response.json()).then((data)=>{
            if(data.status==='error') return alert('יש 5בעיה')
            if(data.status==='success'){
                setValues({...values,planning:1})
                 return alert("נשמר בהצלחה")
                }
        })
        
    }
    const procurement=()=>{
        fetch('http://localhost:9000/project/purchase-phase',{
            method: 'POST',
          headers: {
            "Access-Control-Allow-Origin": "*",'Content-Type': 'application/json',
                "Accept": "application/json"},
          body: JSON.stringify({ username: values.projectName} )
        }).then(response=>response.json()).then((data)=>{
            if(data.status==='error') return alert('יש בעיה')
            if(data.status==='success') {
                setValues({...values,procurement:1})
                return alert("נשמר בהצלחה")
            }
        })
        
    }
    const electric =(e)=>{
        e.preventDefault()
        fetch('http://localhost:9000/project/date-to-electric-company',{
            method: 'POST',
          headers: {
            "Access-Control-Allow-Origin": "*",'Content-Type': 'application/json',
                "Accept": "application/json"},
          body: JSON.stringify({electricCompany: values.electricCompany, username: values.projectName} )
        }).then(response=>response.json()).then((data)=>{
            if(data.status==='error') return alert('יש בעיה1')
            if(data.status==='success') return alert("נשמר בהצלחה")
        })
    }
    const standardsInstitute=(e)=>{
        e.preventDefault()
        fetch('http://localhost:9000/project/date-to-standards-institute',{
            method: 'POST',
          headers: {
            "Access-Control-Allow-Origin": "*",'Content-Type': 'application/json',
                "Accept": "application/json"},
          body: JSON.stringify({standardsInstitute: values.standardsInstitute, username: values.projectName} )
        }).then(response=>response.json()).then((data)=>{
            if(data.status==='error') return alert('יש 2בעיה')
            if(data.status==='success') return alert("נשמר בהצלחה")
        })
        
    }
    const receivElevator=(e)=>{
        e.preventDefault()
        fetch('http://localhost:9000/project/date-receive-elevator',{
            method: 'POST',
          headers: {
            "Access-Control-Allow-Origin": "*",'Content-Type': 'application/json',
                "Accept": "application/json"},
          body: JSON.stringify({receivElevator: values.receivElevator, username: values.projectName} )
        }).then(response=>response.json()).then((data)=>{
            if(data.status==='error') return alert('יש 3בעיה')
            if(data.status==='success') return alert("נשמר בהצלחה")
        })
        
    }
    
    const upload=()=>{
        if(fileUpload == null) return
        
        const fileRef = ref(storge, `files/${fileUpload.name }`)
        uploadBytes(fileRef, fileUpload).then(()=>{
           alert("file uploaded")
        
        })
        fetch('http://localhost:9000/project/upload-work-plan',{
            method: 'POST',
          headers: {
            "Access-Control-Allow-Origin": "*",'Content-Type': 'application/json',
                "Accept": "application/json"},
          body: JSON.stringify({ username: values.projectName} )
        }).then(response=>response.json()).then((data)=>{
            if(data.status==='error') return alert('יש בעיה4')
            if(data.status==='success'){
                setValues({...values, workPlan: '1'})
                alert("נשמר בהצלחה")
                getDownloadURL(ref(storge,`files/${values.projectNum}.pdf`)).then((url)=>{
                    setUrl(url)
           }) 
            }
        })
     


    }
   
    // getDownloadURL(ref(storge,`files/${fileWork}`)).then((url)=>{
    //     setUrl(url)
    //    }) 

  
 
    return (
        <div className='update'>
            <Logout/>
            <div className="details">
            <h1 className="text-center"> עדכון פרטים ל{values.projectName}</h1>
            {/* step A */}
            <form onSubmit={updateA} >
                <div className="form-group mx-sm-3 mb-2 " >
                    <label className="text-dark" htmlFor="workOffice"><strong>הזן מספר ממשרד העבודה</strong></label>
                    <input name="workOffice" placeholder= "מספר ממשרד העבודה" type="text" value={values.workOffice || ''} onChange={e=>setValues({...values,workOffice: e.target.value})} /><i className={values.workOffice? "fa fa-check icon-check" : ""}></i><br/>
                </div>
                <div className="form-group mx-sm-3 mb-2 " >
                    <label className="text-dark" htmlFor="dateSignature"><strong>תאריך חתימת חוזה</strong></label>
                    <input name="dateSignature" placeholder= "תאריך חתימת חוזה" type="date" value={values.dateSignature ||''} onChange={e=>setValues({...values,dateSignature: e.target.value})} /> <i className={values.dateSignature? "fa fa-check icon-check" : ""}></i><br/>
                </div>
                <div className="form-group mx-sm-3 mb-2 " >
                    <label className="text-dark" htmlFor="elevatorType"><strong>סוג מעלית</strong></label>
                    <select name="elevatorType" placeholder='סוג המעלית' type="text"  onChange={e => setValues({...values, elevatorType: e.target.value})} value ={values.elevatorType ||''}>
                                <option value="">{values.elevatorType == null? "בחר סוג מעלית" : values.elevatorType}</option>
                                <option value="MRL" >MRL</option>
                                <option value="Hydraulic" >Hydraulic</option>
                                <option value="maalon">מעלון</option>
                                
                            </select> <i className={values.elevatorType ? "fa fa-check icon-check" : ""}></i><br/>
                </div>
                <div className="form-group mx-sm-3 mb-2 " >
                    <label className="text-dark" htmlFor="endDate"><strong>תאריך סיום משוער</strong></label>
                    <input name="endDate" placeholder= "תאריך סיום" type="date" value={values.endDate ||''} onChange={e=>setValues({...values,endDate: e.target.value})} /> <i className={values.endDate ? "fa fa-check icon-check" : ""}></i><br/>
                </div>
                <button className="btn btn-success m-4" type="submit"> עדכן</button>
            </form>
            {/* step engineer */}
            <div className="mx-sm-3 mb-4 mt-3">
                <h6>אם לפרויקט הזה צריך מהנדס, נא הכנס את שם המהנדס</h6>
                <form onSubmit={addEngineer}>
                    <input className="text-right" name="engineer" placeholder= "שם המהנדס" type="text" value={ values.engineer||''} onChange={e=>setValues({...values,engineer: e.target.value})} /> <i className={values.engineer ? "fa fa-check icon-check" : ""}></i>
                    <button className="btn btn-success m-3" type="submit">שמור</button>
                </form>
            </div>
            {/* step B */}
            <div className=" mx-sm-3 mb-5 ">
                {/* <form onSubmit={planning}> */}
                    <h6>תכנון מול ספקים</h6>

                    {values.planning == null ? <button className="btn btn-success m-3" onClick={planning}>סיימתי לתכנן</button>:<i className="fa fa-check icon-check" ></i>}
                {/* </form> */}
            </div>
            <div className="mx-sm-3 mb-5 ">
                {/* <form onSubmit={procurement}> */}
                    <h6>רכוש את כל חלקי המעלית</h6>
                    
                    {values.procurement == null ? <button className="btn btn-success m-3" onClick={procurement}>רכשתי</button>:<i className="fa fa-check icon-check" ></i>}
                {/* </form> */}
            </div>
            {/* step C */}
            <div className="mx-sm-3 mb-5 ">
                <h5>תוכנית עבודה</h5>
                <h6>זה הזמן לשבת ולכתוב תוכנית עבודה מסודרת </h6>
                <p>שם הקובץ חייב להיות מספר תיק הפרויקט ומסוג PDF</p>
                
                {values.workPlan ? <>
                <p><i className="fa fa-check icon-check" ></i><a href={Url} >גש לקובץ</a></p>
                </>
                :
                <>
                <input type="file" onChange={(e)=>{setFileUpload(e.target.files[0])}}/>
                <br/>
                <button className='btn btn-success m-3' onClick={upload}>שמור קובץ</button>
            </>}
            
                
            </div>
            {/* step D */}
            <div className="mx-sm-3 mb-5 ">
                <form onSubmit={electric}>
                    <h5>אנחנו צריכים טופס 4, תתקשר לחברת החשמל ותאם איתם תאריך להגעה </h5>
                    <input name="electricCompany" placeholder= "תאריך הגעה של חברת חשמל" type="date" value={onlyDate(values.electricCompany)||''} onChange={e=>setValues({...values,electricCompany: e.target.value})} />
                     <i className={values.electricCompany ? "fa fa-check icon-check" : ""}></i>
                     
                    <button type="submit" className="btn btn-success m-3">שמור</button>
                </form>

            </div>
            {/* step E */}
            <div className="mx-sm-3 mb-5 ">
                <form onSubmit={standardsInstitute}>
                    <h5>יפה אנחנו לקראת סיום, בואו נזמין את מכון התקנים לביצוע בדיקה </h5>
                    <input name="standardsInstitute" placeholder= "תאריך הגעה של מכון התקנים" type="date" value={ onlyDate(values.standardsInstitute)||''} onChange={e=>setValues({...values,standardsInstitute: e.target.value})} />
                     <i className={values.standardsInstitute ? "fa fa-check icon-check" : ""}></i>
                     
                    <button type="submit" className="btn btn-success m-3">שמור</button>
                </form>
            </div>
            {/* step F and end */}
            <div className="mx-sm-3 mb-2">
                <form onSubmit={receivElevator}>
                    <h5>עברנו את הבדיקה של מכון התקנים בהצלחה, נותר לקבוע עם הדיירים יום לקבלת המעלית</h5>
                    <input name="receivElevator" placeholder= "תאריך קבלת מעלית" type="date" value={onlyDate(values.receivElevator)||''} onChange={e=>setValues({...values,receivElevator: e.target.value})} />
                     <i className={values.receivElevator ? "fa fa-check icon-check" : ""}></i>
                     
                    <button type="submit" className="m-3 btn btn-success ">שמור</button>
                </form>
            </div>

        </div>
            
        </div>

    );
}
export default ProjectDetails

