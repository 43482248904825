import React ,{useState}from "react";
import Axios from 'axios';

function NewProject(props) {
    // Axios.defaults.withCredentials = false
    const [values, setValues] = useState({
        projectNum: '',
        password: '',
        contact: '',
        phoneNum: '',
        projectNum: ''
    })
    
    const add = (e) => {
        e.preventDefault()
        fetch('http://localhost:9000/manager/add',{
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json'},
            body: JSON.stringify(values),
            
        }).then((response)=>response.json()).then((result)=>{
            if(result.message === 'error' ){
                
                return alert("שגיאה בשמירת הנתונים")
 
            }if(result.message === 'success'){
                alert("נשמר בהצלחה")
                props.close()
            }
            
        })
    }
    return(
        <div>
           <div className='add_user'>
            <h1 className='title-add' > הוספת פרויקט חדש </h1>
                <form className='form-add_user' onSubmit={add}>
                    
                    <div className="mb-3">
                        <label><strong>שם הפרויקט</strong></label>
                        <input required name="projectName" placeholder="רחוב+מספר+עיר" type="text" value={values.projectName ||''} onChange={e=>setValues({...values, projectName: e.target.value})} className="form-control rounded-0 text-center"/>
                    </div>
                    <div className="mb-3">
                        <label><strong>סיסמא</strong></label>
                        <input required name="password" value={values.password||''} placeholder="סיסמא" type="password" onChange={e=>setValues({...values, password: e.target.value})} className="form-control rounded-0 text-center"/>
                    </div>
                    <div className="mb-3">
                        <label><strong>איש הקשר</strong></label>
                        <input required name="contact" placeholder='שם מלא' type="contact" value={values.contact||''} onChange={e=>setValues({...values, contact: e.target.value})} className="form-control rounded-0 text-center"/>
                    </div>
                    <div className="mb-3">
                        <label><strong>מספר טלפון </strong></label>
                        <input required name="phoneNum" placeholder='טלפון איש קשר' type="phoneNum" value={values.phoneNum||''} onChange ={e=>setValues({...values, phoneNum: e.target.value})} className="form-control rounded-0 text-center"/>
                    </div>
                    <div className="mb-3">
                        <label><strong>מספר תיק פרויקט</strong></label>
                        <input required name="phoneNum" placeholder='תיק פרויקט' type="projectNum" value={values.projectNum||''} onChange ={e=>setValues({...values, projectNum: e.target.value})} className="form-control rounded-0 text-center"/>
                    </div>
                    
                    <button className='btn btn-success w-50 d-flex justify-content-center ' type="submit"  > הוסף</button>
                    
                    <div id="snackbar">  הפרטים נשמרו בהצלחה</div>
                </form>
            </div>
           
        </div>
    )
}
export default NewProject