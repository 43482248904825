import React from 'react'
import './Footer.css';
import 'bootstrap-icons/font/bootstrap-icons.css'

//import * as Icons from "react-icons/fa";

function Footer() {
    return (
        <div className='footer-container'>
            <div className='places_list'>
                <a href='/פרויקטים' className='place-footer'><h6>הוספת מעליות משרדים</h6></a>
                <a href='/פרויקטים' className='place-footer'><h6>הוספת מעליות מקום ציבורי</h6></a>
                <a href='/פרויקטים' className='place-footer'><h6>התקנת מעליות בית פרטי</h6></a>
                <a href='/פרויקטים' className='place-footer'><h6>התקנת מעלית בניין ישן</h6></a>
                <a href='/פרויקטים' className='place-footer'><h6>הוספת מעלית פנטהאוז</h6></a>
                <a href='/פרויקטים' className='place-footer'><h6>הוספת מעליות בניין קיים</h6></a>

            </div>
            
            <section className="social-media">
                <div className="social-media-wrap">
                    
                    <div className="social-icons"> 
                    
                     <a 
                     className="facebook"
                     href="https://www.facebook.com/maofma"
                    //  target="_blank"
                    >
                     
                     
                         <i className="bi bi-facebook"> </i>
                        בואו לבקר בדף הפייסבוק שלנו  
                         </a>
                    </div>
                    <small className='website-lefts'> מעוף מעליות © 2024</small>
                    <div>
                        <p>הוספת מעלית | התקנת מעלית | שירות למעליות | חברת מעליות | מעליות | מעלונים | מעליות מטבח </p>
                    </div>
                </div>
            </section>
            
        </div>
    )
}

export default Footer