import React,{useEffect, useState} from 'react'
import '../../App.css'
import './Manager.css'
import {useNavigate}from 'react-router-dom'
import Popup from '../Popup'
import CustomerCard from '../CustomerCard'
import NewProject from '../NewProject'
import Logout from '../Logout';
import {useLocation} from 'react-router-dom'

function Manager(){
  const projectNum = useLocation().state
    const navigate = useNavigate()
    const [popupOpen,setPopup] = useState(false)
   
    const [usernameList, setUsersList] = useState([])
    
    useEffect(() => {
     
      fetch("http://localhost:9000/manager/get-all-projects",{
        method: 'GET',
        headers:  {
          "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/json',
      "Accept": "application/json"},
  
      }).then((response) => response.json()).then((data)=>{
        setUsersList(data.myData)
      })
        
        
        
    }, [usernameList]);
    
    
  const deleteUser = (projectNum, username) => {
    console.log(projectNum)
    fetch(`http://localhost:9000/manager/delete-project?projectNum=${projectNum}`,{
      method: 'DELETE',
        headers: {
          "Access-Control-Allow-Origin": "*",'Content-Type': 'application/json',
              "Accept": "application/json"}
    }).then(response=>response.json()).then((data)=>{
      if(data.status === 'error') return alert("error")
      if(data.status === 'success'){
        alert("success")
        setUsersList(usernameList.filter(val => {return val.projectNum !== username}))
      }
      }).catch(err => {
            console.error(err);
            
    })
    // fetch(`http://localhost:9000/manager/delete-from-info-table?username=${username}`,{
    //   method: 'DELETE',
    //   headers: {'Content-Type': 'application/json',
    //           "Accept": "application/json"}
    // })
    // Axios.delete(`https://elevators-maof.herokuapp.com/deleteUser/${projectNum}/${username}`).then(() => {
      
    //   setUsersList(usernameList.filter(val => {return val.projectNum !== username}))}).catch(err => {
    //     console.error(err);
    //     })
        // Axios.delete(`https://elevators-maof.herokuapp.com/deleteInfo/${user}`)
        
    };
    const togglePopup =()=>{
    
      setPopup(!popupOpen)
    }
    const service =()=>{
      navigate('/מעקב שירות')
    }
    // if(!usernameList) return <p>Fetching data...</p>

    return (
    
         <div className='manager'>
          
          <Logout/>
          <h1 className='title text-center'>שלום מנהל יקר!</h1>
          {projectNum === process.env.REACT_APP_MANAGER_NAME? ( 
            <div>
          <div className='text-center d-flex justify-content-evenly'>
            <button className='btn btn-secondary m-0' onClick={togglePopup}>
              <i className='fa fa-plus-circle'></i> הוספת פרויקט חדש 
            </button>

            <button className='btn btn-secondary m-0' onClick={service}>מעקב אחרי שירות</button>
        
         </div>
         <h5>סה"כ מעליות קיימות: {usernameList.length}</h5>
         
           {popupOpen && <Popup handleClose={togglePopup}
           content={<NewProject close={togglePopup}/>
           } />}
         
             
             
             <div className='update-projects'>
                
                 <div className='grid-projects'>
                  {usernameList.length !==0 ? 
                    usernameList.map((val, index)=>{
                        return (
                        <div className='users-card' key={index}>  
                         
                          <CustomerCard title={val.projectName} num={val.projectNum} func={deleteUser}/>
                           
                       </div>
                       )
                    }) : <p>Fetching data...</p>
                  }
        
                  </div>
                  
            </div>
            </div>):<div>חייב סיסמא של מנהל</div>}
         
            
        </div>
        
    );
       

    
}
export default Manager